import DisplayForm from './DisplayForm';
import DisplayThanks from './DisplayThanks';
import Form from 'react-bootstrap/Form'
import { postData } from '../service/ApiService';
import { buildFormPayload } from '../utils/AppUtils';
import { useState } from 'react';


export const DisplayFormPage = () => {

    const [submitted, isSubmitted] = useState(false)
    const TRAVEL_FORM_API = 'https://5fe2sv4066.execute-api.us-east-2.amazonaws.com/dev/mySampleLambda'

    /**
     * Method handles form submission
     * @param {*} event 
     */
    const handleFormSubmit = event => {
        // prevents page from defaulting on page click
        event.preventDefault()

        let formPayload = buildFormPayload(event);
        console.log(formPayload)
        // POST data
        postData(TRAVEL_FORM_API, formPayload)
            .then(data => {
                isSubmitted(true)
                console.log("data:" + JSON.stringify(formPayload)); // JSON data parsed by `data.json()` call
            }).catch(error => console.log('This is Error message -', error));
    }

    return (
        <Form
            className="Containers"
            onSubmit={handleFormSubmit} >
            {!submitted ? <DisplayForm /> : <DisplayThanks />}
        </Form>
    );
}
export default DisplayFormPage