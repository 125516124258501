import FormSelect from '../input/FormSelect';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

export const DisplayKidSelection = () => {
    
    return (
      <FormInputRow>
        <FormInputGroup md="4">
        <FormSelect
            as="select"
            defaultValue="0"
            name="num_of_kids"
            label="How many kids?" 
        >
          <option>0</option>,
          <option>1</option>,
          <option>2</option>,
          <option>3</option>,
          <option>4</option>,
          <option>6</option>,
          <option>7</option>,
          <option>8</option>,
          <option>9+</option>
          </FormSelect>
        </FormInputGroup>
      </FormInputRow>
    );
}
export default DisplayKidSelection