import FormInput from '../input/FormInput';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

const DisplayContactReference = () => {

    return (
        <FormInputRow>
        <FormInputGroup md="4">
          <FormInput
            name="email"
            label="Email"
            placeHolder="jdoe@email.com"
          />
        </FormInputGroup>
        <FormInputGroup md="4">
        <FormInput
            name="contact_number"
            label="Contact Number" 
            placeHolder="(123)-567-9999"
          />
        </FormInputGroup>
      </FormInputRow>
    );
}
export default DisplayContactReference