import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';
import FormCheckBox from '../input/FormCheckBox';

export const DispalyCruiseBookingTypes = () => {

    return (
        <FormInputRow>
            <FormInputGroup md="4">
                <FormCheckBox
                    formLabel="What type of cabin?"
                    checkBoxLabel="Inside"
                    type="radio"
                    name={"cruise_booking"}
                    id={"inside"}
                />
                <FormCheckBox
                    checkBoxLabel="OceanView"
                    type="radio"
                    name={"cruise_booking"}
                    id={"oceanView"}
                />
                <FormCheckBox
                    checkBoxLabel="Balcony"
                    type="radio"
                    name={"cruise_booking"}
                    id={"balcony"}
                />
                <FormCheckBox
                   checkBoxLabel="Suite"
                    type="radio"
                    name={"cruise_booking"}
                    id={"suite"}
                />
                <FormCheckBox
                    checkBoxLabel="Other"
                    type="radio"
                    name={"cruise_booking"}
                    id={"other"}
                />
            </FormInputGroup>
        </FormInputRow>
    );
}