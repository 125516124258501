import FormCheckBox from '../input/FormCheckBox';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';
import { useState } from 'react';
import DisplayLandBookingType from './DisplayLandBookingType';
import { DispalyCruiseBookingTypes } from './DisplayCruiseBookingTypes';



export const DisplayTravelType = ({method}) => {

  const [travelType, setTravelType] = useState()
  const [land, isLand]= useState()
  const [cruise, isCruise]= useState()

   /**
   * handles travel type to display land or cruise selctions
   * @param {*} event 
   */
    const handleTravelType = (event) => {
      console.log("App Method: " + event.target.value)
      handleRadioBtn(event)
      setTravelType(event.target.value)
    }
  
  const handleRadioBtn = (event) => {
    event.currentTarget.value === "Land" ? isLand(true) :isLand(false)
    event.currentTarget.value === "Cruise" ? isCruise(true) :isCruise(false)
  }

  const TRAVEL_TYPE = {
    LAND: "Land",
    CRUISE: "Cruise"
  }

  return (
    <>
    <FormInputRow>
      <FormInputGroup md="8">
        <FormCheckBox
          formLabel="Tell us a little bit about where you'd like to go what kind 
                     of experience you're interested in once you get there!"
          checkBoxLabel={TRAVEL_TYPE.LAND}
          type="radio"
          onChange={handleTravelType}
          checked={land === true ? true : false}
        />

        <FormCheckBox
          checkBoxLabel={TRAVEL_TYPE.CRUISE}
          type="radio"
          onChange={handleTravelType}
          checked={cruise === true ? true : false}
        />

      </FormInputGroup>
    </FormInputRow>
    
    {travelType === TRAVEL_TYPE.LAND && <DisplayLandBookingType /> }
    {travelType === TRAVEL_TYPE.CRUISE && <DispalyCruiseBookingTypes /> }
   
    </>
  );
}
export default DisplayTravelType