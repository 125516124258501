import FormInput from '../input/FormInput';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

export const DisplayDepartureType = () => {
   
    return (
      <FormInputRow>
      <FormInputGroup md="4">
      <FormInput
          name="departure_airport"
          label="Departure Airport" 
          placeHolder="Atlanta"
        />
      </FormInputGroup>
    </FormInputRow>
    );
}
export default DisplayDepartureType