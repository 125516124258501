import FormCheckBox from '../input/FormCheckBox';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

export const DisplayFlexibleDates = () => {
    
    return (
      <FormInputRow>
      <FormInputGroup md="8">
      <FormCheckBox
        formLabel="Are your dates flexible?"
        checkBoxLabel="yes"
        type="radio"
        name={"flexible_dates"}
        id={"yes"}
       />

       <FormCheckBox
        checkBoxLabel="no"
        type="radio"
        name={"flexible_dates"}
          id={"no"}
       />
       
      </FormInputGroup>
    </FormInputRow>
    );
}
export default DisplayFlexibleDates