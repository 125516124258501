import FormCheckBox from '../input/FormCheckBox';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

export const DisplayContactPreference = () => {
      
    return (
        <FormInputRow>
        <FormInputGroup md="8">
        <FormCheckBox
          formLabel="How do you Prefer to be contacted?"
          checkBoxLabel="phone"
          type="radio"
          name={"contact_preference"}
          id={"phone"}
         />
         <FormCheckBox
          checkBoxLabel="email"
          type="radio"
          name={"contact_preference"}
          id={"email"}
         />
         <FormCheckBox
          checkBoxLabel="both"
          type="radio"
          name={"contact_preference"}
          id={"both"}
         />
        </FormInputGroup>
      </FormInputRow>
    );
}
export default DisplayContactPreference