import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';
import { DateRangePickers } from './datePicker/DateRangePickers';

export const DisplayDatePicker = () => {

    return (
      <FormInputRow>
      <FormInputGroup md="4">
        <DateRangePickers label="Pick Your Dates of Travel" />
      </FormInputGroup>
    </FormInputRow>
    );
}

