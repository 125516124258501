import './Announcement.css'


export const announcements = () => {

    return(
        <div >
            <h1 className="Center" >Travel Interest Form</h1>
            <p>Hello, Thanks so much for your interest in Love's Awesome Adventures. 
                I'd love to get to know you better before we chat! 
                Can you tell me a little bit about your travel interests?
            </p>
        </div>
    )
}