import Form from 'react-bootstrap/Form'
import React from 'react';

export const FormCheckBox = ({formLabel, checkBoxLabel, type, onChange, checked, id, name})=> {
  
    return (
      <>
      {formLabel != null && <Form.Label>{formLabel}</Form.Label>}
        <Form.Check
            type={type}
            label={checkBoxLabel}
            value={checkBoxLabel}
            name={name}
            id={id}
            onChange={onChange}
            checked={checked}
            
        />
      </>
    );
}

// FormInput.defaultProps = {
//     name: "name",
//     placeHolder: "John Doe",
//     label: "Name",
//     formGridFirstName: "formGridFirstName"
// }

// FormInput.propTypes = {
//     name: PropTypes.string,
//     placeHolder: PropTypes.string,
//     label: PropTypes.string,
//     formGridFirstName: PropTypes.string
// }

export default FormCheckBox

