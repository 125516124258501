import FormInputRow from './input/FormInputRow';
import FormInputGroup from './input/FormInputGroup';
import React from 'react';

export const DisplayThanks = () => {

    const reloadAfterSubmission = () => {
        setTimeout(() => { window.location.reload(); }, 3000);
      }
    return (
        <>
            <FormInputRow>
                <FormInputGroup md="4">
                    <h3 className="Center">Thanks for the form submission</h3>
                </FormInputGroup>
            </FormInputRow>
            
            {reloadAfterSubmission()}
        </>
    );
}
export default DisplayThanks