import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';
import FormCheckBox from '../input/FormCheckBox';
import { useState } from 'react';
import { DisplayDepartureType } from './DisplayDepartureType';
import DisplayCarRental from './DisplayCarRental';



const DisplayLandBookingType = () => {

    const [flights, setFlights]= useState(false)
    const [flightHotelCar, isFlightHotelCar]= useState()
    const [carRentalOnly, isCarRentalOnly]= useState() //
    const [hotelOnly, isHotelOnly]= useState()

    /**
     * 
     * @param {*} event 
     */
    const handleBookingType = (event) => {
        console.log("App Method: " + event.target.value)
        handleRadioBtn(event)
    }

    /**
     * 
     * @param {*} event 
     */
    const handleRadioBtn = (event) => {
        event.currentTarget.value === "Flights" ? setFlights(true) : setFlights(false)
        event.currentTarget.value === "Flight, Hotel and Car Rental" ? isFlightHotelCar(true) : isFlightHotelCar(false)
        event.currentTarget.value === "Car Rental Only" ? isCarRentalOnly(true) : isCarRentalOnly(false)
        event.currentTarget.value === "Hotel Only" ? isHotelOnly(true) : isHotelOnly(false)
    }


    return (
        <>
        <FormInputRow>
            <FormInputGroup md="4">
                <FormCheckBox
                    formLabel="What would you like to have included in your booking?"
                    checkBoxLabel="Flights"
                    type="radio"
                    name={"land_booking"}
                    id={"flights"}
                    onChange={handleBookingType}
                    checked={flights === true ? true : false}
                />

                <FormCheckBox
                    checkBoxLabel="Flight, Hotel and Car Rental"
                    type="radio"
                    name={"land_booking"}
                    id={"flightHotelCar"}
                    onChange={handleBookingType}
                    checked={flightHotelCar === true ? true : false}
                />

                <FormCheckBox
                    checkBoxLabel="Car Rental Only"
                    type="radio"
                    name={"land_booking"}
                    id={"carRentalOnly"}
                    onChange={handleBookingType}
                    checked={carRentalOnly === true ? true : false}
                />

                <FormCheckBox
                    checkBoxLabel="Hotel Only"
                    type="radio"
                    name={"land_booking"}
                    id={"hotelOnly"}
                    onChange={handleBookingType}
                    checked={hotelOnly === true ? true : false}
                />
            </FormInputGroup>
        </FormInputRow>
        
        { flights && <DisplayDepartureType />}
        { flightHotelCar && <DisplayDepartureType />}
        { flightHotelCar && <DisplayCarRental />}
        { carRentalOnly && <DisplayCarRental />}
        </>
    );
}

export default DisplayLandBookingType