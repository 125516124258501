import Form from 'react-bootstrap/Form'

export const FormSelect = ({onChange, name, as, label, defaultValue, children})=> {
  
    return (
      <>
        {label != null && <Form.Label>{label}</Form.Label>}
          <Form.Control
            name={name}
            as={as}
            defaultValue={defaultValue}
            
          >
            {children}
          </Form.Control>
      </>
    );
}

export default FormSelect

