import FormInput from '../input/FormInput';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

const DisplayBudget = () => {
    
    return (
        <FormInputRow>
        <FormInputGroup md="4">
          <FormInput
            name="budget_amount"
            label="What is your budget?"
            placeHolder="budget amount"
          />
        </FormInputGroup>
      </FormInputRow>
    );
}

export default DisplayBudget