import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {announcements} from '../components/Announcements/Announcements';

const Banner = () => {

    return (
        <Row >
            <Col md={12}>
               {announcements()}
            </Col>
        </Row>
    );
}
export default Banner