import './App.css';
import 'react-nice-dates/build/style.css'
import { hot } from 'react-hot-loader'
import Container from 'react-bootstrap/Container';
import Header from './components/Header';
import DisplayFormPage from './components/DisplayFormPage';

const App = () => {

  return (
  <>
  {/* { submitted && } */}
    <Container>
      <Header />
      {/* <Banner /> */}
      <DisplayFormPage />
    </Container>
  </>
  );
}
export default hot(module)(App);
