import FormInput from '../input/FormInput';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';
import React from 'react';

export const DisplayName = ({onChange, name, placeHolder, label})=> {

    return (
      <FormInputRow>

      <FormInputGroup md="4">
        <FormInput
          name="first_name"
          label="First Name"
          placeHolder="Jon"
        />
      </FormInputGroup>

      <FormInputGroup md="4">
      <FormInput
          name="last_name"
          label="Last Name" 
          placeHolder="Doe"
        />
      </FormInputGroup>
      
    </FormInputRow>
    );
}

export default DisplayName

