import '../components/header.css'

const Header = () =>  {

    return(
        <div className="Header">
            <h1>Love's Awesome Adventures</h1>
            <h3>Life Is An Adventure</h3>
        </div>
    )
}
export default Header