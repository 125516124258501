import { enGB } from 'date-fns/locale'
import React, { useState } from 'react'
import { format } from 'date-fns'
import { DateRangePicker, START_DATE, END_DATE } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'
import Form from 'react-bootstrap/Form'

export const DateRangePickers = ({label}) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [focus] = useState(START_DATE)

  return (
    <>
          {label != null && <Form.Label>{label}</Form.Label>}
           
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            minimumDate={new Date()}
            minimumLength={1}
            format='MM/dd/yyyy'
            locale={enGB}
          >
            
            {({ startDateInputProps, endDateInputProps, focus }) => (
              
              <div className='date-range'>
                <input
                  className={'input' + (focus === START_DATE ? ' -focused' : '')}
                  {...startDateInputProps}
                  
                  placeholder='Date From:'
                  value={startDate ? format(startDate, 'MM/dd/yyyy', { locale: enGB }) : ''}
                  name="start_date"
               
                />
                <span className='date-range_arrow' />
                <input
                  className={'input' + (focus === END_DATE ? ' -focused' : '')}
                  {...endDateInputProps}
                  placeholder='Date To:'
                  value={endDate ? format(endDate, 'MM/dd/yyyy', { locale: enGB }) : ''}
                  name="end_date"
                  // onFocus={run}
                />
              </div>
            )}
          </DateRangePicker>
    </>
  )
}

