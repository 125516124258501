import FormCheckBox from '../input/FormCheckBox';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

export const DisplayTravelOcassion = () => {
  
    return (
      <FormInputRow>
      <FormInputGroup md="8">
      <FormCheckBox
        formLabel="Traveling special occassion?"
        checkBoxLabel="Anniversary"
        name="anniversary"
        type="checkbox"
       />
       <FormCheckBox
        checkBoxLabel="Birthday"
        type="checkbox"
        name="birthday"
       />
       <FormCheckBox
        checkBoxLabel="Destination Bachelor/Bachelorette"
        type="checkbox"
        name="destination_bachelor_bachelorette"
       />
       <FormCheckBox
        checkBoxLabel="Destination Wedding"
        type="checkbox"
        name="destination_wedding"
       />
       <FormCheckBox
        checkBoxLabel="Family Reunion"
        type="checkbox"
        name="family_reunion"
       />
       <FormCheckBox
        checkBoxLabel="Romance"
        type="checkbox"
        name="romance"
       />
       <FormCheckBox
        checkBoxLabel="Just to getaway"
        type="checkbox"
        name="getaway"
       />
      </FormInputGroup>
    </FormInputRow>
    );
}
export default DisplayTravelOcassion