import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';
import { Button } from 'react-bootstrap';

const DisplayButton = () => {

    return (
        <FormInputRow>
            <FormInputGroup md="4">
                <Button variant="primary" type="submit">
                    Submit Form
                </Button>
            </FormInputGroup>
        </FormInputRow>
    );
}

export default DisplayButton