import FormCheckBox from '../input/FormCheckBox';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

export const DisplayActivities = () => {
    
    return (
      <FormInputRow>
      <FormInputGroup md="8">
      <FormCheckBox
        formLabel="Activities you may want to do"
        checkBoxLabel="Adventure/Active"
        type="checkbox"
        name={"adventure"}
        id={"adventure"}
        
       />
       <FormCheckBox
        checkBoxLabel="Kid/Family Friendly"
        type="checkbox"
        name={"kid_family"}
        id={"kidFamilyFriendly"}
       />
       <FormCheckBox
        checkBoxLabel="Food/Wine"
        type="checkbox"
        name={"food_wine"}
        id={"foodWine"}
       />
       <FormCheckBox
        checkBoxLabel="Rest & Relaxation"
        type="checkbox"
        name={"rest_relaxation"}
        id={"restRelaxation"}
       />
       <FormCheckBox
        checkBoxLabel="Night Life"
        type="checkbox"
        name={"night_life"}
        id={"nightLife"}
       />
       <FormCheckBox
        checkBoxLabel="LBGTQAI+"
        type="checkbox"
        name={"lbgtqai+"}
        id={"lbgtqai+"}
       />
       <FormCheckBox
        checkBoxLabel="Mountain/Nature Trails"
        type="checkbox"
        name={"mountain_nature"}
        id={"mountain"}
       />
       <FormCheckBox
        checkBoxLabel="Other"
        type="checkbox"
        name={"other"}
        id={"other"}
       />
      </FormInputGroup>
    </FormInputRow>
    );
}
export default DisplayActivities