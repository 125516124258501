import FormInput from '../input/FormInput';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

export const DisplayDestination = () => {

    return (
        <FormInputRow>
        <FormInputGroup md="4">
        <FormInput
            name="destination"
            label="What's your destination?" 
            placeHolder="Destination"
          />
        </FormInputGroup>
      </FormInputRow>
    );
}

