import '../../App.css';
import React from 'react';
import Row from 'react-bootstrap/Row'
import './input.css'

export const FormInputRow = ({children})=> {

    return (
      <>
        <Row className="Input" >
          {children}
        </Row>
      </>
    );
}

export default FormInputRow

