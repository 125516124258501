import DisplayName from '../components/form/DisplayName';
import DisplayContactReference from '../components/form/DisplayContactReference';
import DisplayContactPreference from '../components/form/DisplayContactPreference';
import { DisplayDestination } from '../components/form/DisplayDestination';
import DisplayFlexibleDates from '../components/form/DisplayFlexibleDates';
import DisplayTravelType from '../components/form/DisplayTravelType';

import DisplayAdultSelection from '../components/form/DisplayAdultSelection';
import DisplayKidSelection from '../components/form/DisplayKidSelection';
import DisplayTravelOcassion from '../components/form/DisplayTravelOcassion';
import DisplayActivities from '../components/form/DisplayActivities';
import DisplayBudget from '../components/form/DisplayBudget';
import { DisplayComments } from '../components/form/DisplayComments';
import DisplayButton from '../components/form/DisplayButton';
import { DisplayDatePicker } from '../components/form/DisplayDatePicker';

import Banner from '../components/Banner';

export const DisplayForm = () => {

    return (
        <>
            <Banner />
            <DisplayName />
            <DisplayContactReference />
            <DisplayContactPreference />
            <DisplayDestination />
            <DisplayDatePicker />
            <DisplayFlexibleDates />
            <DisplayTravelType />
            <DisplayAdultSelection />
            <DisplayKidSelection />
            <DisplayTravelOcassion />
            <DisplayActivities />
            <DisplayBudget />
            <DisplayComments />
            <DisplayButton />
        </>
    );
}
export default DisplayForm