import FormSelect from '../input/FormSelect';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';
import { useState } from 'react';

export const DisplayCarRental = () => {

  const [rentalType]= useState("standard")
    
    return (
      <FormInputRow>
        <FormInputGroup md="4">
        <FormSelect

            as="select"
            defaultValue={rentalType}
            name="rental_type" 
            label="What type of car rental?" 
        >
        <option value="economy">Economy/Compact</option>,
        <option value="standard">Standard</option>,
        <option value="full">Full Size</option>,
        <option value="suv">Suv/Van</option>,
        <option value="luxury">Luxury</option>
        </FormSelect>
        </FormInputGroup>
      </FormInputRow>
    );
}
export default DisplayCarRental