import FormInput from '../input/FormInput';
import FormInputRow from '../input/FormInputRow';
import FormInputGroup from '../input/FormInputGroup';

export const DisplayComments = () => {

    return (
        <FormInputRow>
        <FormInputGroup md="4">
          <FormInput
            as="textarea"
            name="extra_comments"
            label="If you have other questions about your trip please leave them below"
            placeHolder="Leave a comment here"
          />
        </FormInputGroup>
      </FormInputRow>
    );
}
