import Form from 'react-bootstrap/Form'
import React from 'react';
import Col from 'react-bootstrap/Col'
export const FormInputGroup = ({children}, md)=> {

    return (
      <>
        <Form.Group className="Padding" as={Col} md={md} controlId="formGridPassword">
          {children}
        </Form.Group>
      </>
    );
}

export default FormInputGroup

