import Form from 'react-bootstrap/Form'
import React from 'react';
import PropTypes from 'prop-types'

export const FormInput = ({onChange, name, placeHolder, label, as})=> {

    return (
      <>
        {label != null && <Form.Label>{label}</Form.Label>}
          <Form.Control
            
            as={as}
            name={name}
            placeholder={placeHolder}
            // value={inputValue}
            // onChange={(e) => setInputValue(e.target.value) }
            // onBlur={()=> onChange(name, inputValue)}
          />
      </>
    );
}

FormInput.defaultProps = {
    name: "name",
    placeHolder: "John Doe",
    label: "Name",
    formGridFirstName: "formGridFirstName"
}

FormInput.propTypes = {
    name: PropTypes.string,
    placeHolder: PropTypes.string,
    label: PropTypes.string,
    formGridFirstName: PropTypes.string
}

export default FormInput

